import * as React from "react";
import styled from "styled-components";
import { BACKGROUND_COLOR } from "../../theme";
import Logo from "../atoms/Logo";

/**
 * アカウント関連のテンプレート
 * ログインと会員登録
 */
const AccountTemplate: React.FC<AccountTemplateProps> = ({
  children,
  title,
}) => {
  return (
    <Root>
      <Background />
      <Container>
        <ServiceName>
          <Logo />
        </ServiceName>
        <Inner>
          <h3>{title}</h3>
          {children}
        </Inner>
      </Container>
    </Root>
  );
};
export default AccountTemplate;
export interface AccountTemplateProps {
  title: string;
}

const Root = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #693c26;
  z-index: -1;
`;

const ServiceName = styled.h1`
  width: 100%;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  font-size: 24px;
  font-weight: bold;
`;

const Container = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: flex-start;
  width: 450px;
  margin: 0 0 10px;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0),
    0 0 0 rgba(16, 22, 26, 0);
  background-color: ${BACKGROUND_COLOR};
  padding: 3rem;
  transition: box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
    -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
    box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
    box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
    -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
`;

const Inner = styled.div`
  width: 350px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  h3 {
    margin-bottom: 8px;
    font-size: 16px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }
`;
