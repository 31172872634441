import * as React from "react";
import styled from "styled-components";

const Container: React.FC = ({ children }) => {
  return <Root>{children}</Root>;
};
export default Container;

const Root = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
`;
