/**
 * APIを叩くReact Hooks
 */
import { useEffect, useState } from "react";
import { getAsync } from "../modules/apiClient";
import { AxiosError } from "axios";

export const useGetApi = <ResponseDataType>(endpoint: string) => {
  const [loading, setLoading] = useState(true);
  const [statusCode, setStatusCode] = useState(100);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<ResponseDataType | null>(null);

  async function getData() {
    try {
      const response = await getAsync<ResponseDataType>(endpoint);
      setStatusCode(response.status);
      setData(response.data);
    } catch (e) {
      // 404エラーなどもこちら
      setError(e);
      if (e.response) {
        e.response.status && setStatusCode(e.response.status);
        e.response.data && setData(e.response.data);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint]);

  return { data, loading, statusCode, error };
};
