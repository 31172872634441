import * as React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
const PrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button variant="contained" color="primary" {...rest}>
      {children}
    </Button>
  );
};
export default PrimaryButton;
