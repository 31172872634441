import React from "react";
import AccountTemplate from "../../templates/AccountTemplate";
import Container from "../../atoms/Container";

const SignupComplete: React.FC = (_) => {
  return (
    <AccountTemplate title="会員登録">
      <Container>
        会員登録が完了しました
        後ほどスタッフから登録したメールアドレス宛へ連絡します。
      </Container>
    </AccountTemplate>
  );
};
export default SignupComplete;
