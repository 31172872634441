import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const getIntervalTxt = (interval: number): string => {
  switch (interval) {
    case 1:
      return "日";
    case 2:
      return "週";
    case 3:
      return "月";
    case 4:
      return "年";
  }
  return "";
};
const Pricing: React.FC<PricingProps> = ({
  planId,
  title,
  description,
  subheader,
  price,
  buttonVariant,
  buttonText,
  featured = false,
  interval,
  needButton = true,
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid
      item
      key={title}
      xs={12}
      sm={title === "Enterprise" ? 12 : 6}
      md={needButton ? 4 : 12}
    >
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{ align: "center" }}
          action={featured ? <StarIcon /> : null}
          className={classes.cardHeader}
        />
        <CardContent>
          <div className={classes.cardPricing}>
            <Typography component="h2" variant="h3" color="textPrimary">
              {price.toLocaleString()}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              円/{getIntervalTxt(interval)}
            </Typography>
          </div>
          <ul>
            <Typography
              style={{ whiteSpace: "pre-wrap" }}
              component="li"
              variant="subtitle1"
              align="left"
            >
              {description}
            </Typography>
          </ul>
        </CardContent>
        {needButton && (
          <CardActions>
            <Button
              fullWidth
              variant={buttonVariant}
              color="primary"
              onClick={() => {
                history.push(`/signup?planId=${planId}`);
              }}
            >
              {buttonText}
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};
export default Pricing;

export interface PricingProps {
  title: string;
  price: number;
  buttonText: string;
  description: string;
  subheader: string;
  buttonVariant: "outlined" | "contained";
  featured?: boolean;
  interval: number;
  planId: string;
  needButton?: boolean;
}
