import React, { useImperativeHandle, useRef } from "react";
import { InputBaseComponentProps } from "@material-ui/core";
const StripeInput: React.FC<InputBaseComponentProps> = ({
  component: Component,
  inputRef,
  ...props
}) => {
  const elementRef = useRef<HTMLElement>();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current?.focus
  }));
  return (
    <Component
      onReady={(element: HTMLElement) => (elementRef.current = element)}
      {...props}
    />
  );
};
export default StripeInput;
