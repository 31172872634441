import React from "react";
import BasicTemplate from "../../templates/BasicTemplate";
import Container from "../../atoms/Container";

const Reservation: React.FC = (_) => {
  return (
    <BasicTemplate>
      <Container>
        <iframe
          title="予約"
          src="https://remotehour.com/widget/EVfwCiYjXsOwxYJhSlpyOExpL6G3/OgoVoHguOwja6yOJqsrs"
          width="100%"
          marginWidth={0}
          marginHeight={0}
          frameBorder={0}
          style={{
            borderStyle: "none",
          }}
        />
      </Container>
    </BasicTemplate>
  );
};

export default Reservation;
