import axios from "axios";
import * as Auth from "./auth";

const timeout = 30000; // ms

function getAuthorizationHeader() {
  const token = Auth.getToken();
  if (token === undefined) {
    return {};
  }
  const options = { headers: { Authorization: `Bearer ${token}` }, timeout };
  return options;
}

/**
 * GETメソッドのHTTPアクセスを非同期で行います
 */
export async function getAsync<TResult>(path: string, params?: {}) {
  const options = getAuthorizationHeader();
  return axios.get<TResult>(`${process.env.REACT_APP_API_DOMAIN}${path}`, {
    params,
    ...options
  });
}

export async function downloadAsync(path: string, params?: {}) {
  const options = getAuthorizationHeader();
  return axios.get(`${process.env.REACT_APP_API_DOMAIN}${path}`, {
    params,
    responseType: "arraybuffer",
    ...options
  });
}

export async function postAsync<TResult>(path: string, params: {}) {
  const options = getAuthorizationHeader();

  return axios.post<TResult>(
    `${process.env.REACT_APP_API_DOMAIN}${path}`,
    params,
    options
  );
}

export async function putAsync<TResult>(path: string, params: {}) {
  const options = getAuthorizationHeader();
  return axios.put<TResult>(
    `${process.env.REACT_APP_API_DOMAIN}${path}`,
    params,
    options
  );
}

export async function deleteAsync(path: string) {
  const options = getAuthorizationHeader();
  return axios.delete(`${process.env.REACT_APP_API_DOMAIN}${path}`, options);
}
