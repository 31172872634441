import * as React from "react";
import { useCallback } from "react";
import { getAsync } from "../modules/apiClient";
import { isAuthorized } from "../modules/auth";
import { GetUserResponse } from "../types/models";

export const userDefault: UserProps = {
  user: { name: "", email: "" },
  updateUser: () => {},
  loaded: false,
};

export const UserContext = React.createContext<UserProps>(userDefault);

const UserStore: React.FC = ({ children }) => {
  const fetchUser = useCallback(async () => {
    try {
      const response = await getAsync<GetUserResponse>("/user");

      setUser((prev: UserProps) => {
        return { ...prev, loaded: true, user: response.data };
      });
    } catch (e) {
      //
    }
  }, []);

  const updateUserStore = useCallback(async () => {
    const response = await getAsync<GetUserResponse>("/user");
    const updated: GetUserResponse = {
      ...response.data,
    };
    setUser((prev: UserProps) => {
      return { ...prev, user: updated };
    });
  }, []);

  const [user, setUser] = React.useState<UserProps>({
    ...userDefault,
    updateUser: updateUserStore,
  });
  const authorized = isAuthorized();

  React.useEffect(() => {
    if (authorized) {
      fetchUser();
    }
  }, [fetchUser, authorized]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserStore;

export interface UserProps {
  user: GetUserResponse;
  loaded: boolean;
  updateUser: () => void;
}
