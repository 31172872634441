import React from "react";
import BasicTemplate from "../../templates/BasicTemplate";
import PostCard from "../../molucules/ArticleCard";
import { useGetApi } from "../../../hooks/useGetApi";
import { GetArticleListResponse } from "../../../types/models";
import Grid from "@material-ui/core/Grid";
import Container from "../../atoms/Container";

const Article: React.FC = (_) => {
  const { data } = useGetApi<GetArticleListResponse>(
    false ? `/nanala` : `/articles`
  );
  return (
    <BasicTemplate>
      <Container>
        <Grid container spacing={3}>
          {data?.articles.map(
            ({ title, imageUri, articleId, postedAt, googleDriveUrl }) => (
              <Grid item xs={12} sm={6} key={articleId}>
                <PostCard
                  title={title}
                  image={imageUri}
                  articleId={articleId}
                  postedAt={postedAt}
                  googleDriveUrl={googleDriveUrl}
                />
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </BasicTemplate>
  );
};

export default Article;
