import React from "react";
import styled from "styled-components";

const VideoCard: React.FC<VideoCardProps> = ({ title, src }) => {
  return (
    <PostCardWrapper>
      <Video
        preload="metadata"
        src={src}
        controls
        onContextMenu={() => false}
        controlsList="nodownload"
      />
      <Overview>
        <Header>{title}</Header>
      </Overview>
    </PostCardWrapper>
  );
};

export default VideoCard;

export interface VideoCardProps {
  articleId: number;
  title: string;
  src: string;
}

const Video = styled.video`
  width: 100%;
`;

const Overview = styled.div`
  padding: 8px 0;
`;

const PostCardWrapper = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid #dbdbdb;
`;

const Header = styled.h3`
  margin: 0 0 8px;
`;
