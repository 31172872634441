import * as React from "react";
import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { PRIMARY_COLOR } from "../../theme";

const NavItem: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <Item>
      {/^https?:\/\//.test(to as string) ? (
        <NavA href={to as string} target="_blank" rel="noopener">
          {children}
        </NavA>
      ) : (
        <NavLink to={to}>{children}</NavLink>
      )}
    </Item>
  );
};
export default NavItem;

const Item = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
`;

const NavLink = styled(Link)`
  @media (min-width: 992px) : {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  display: block;
  padding: 0.8rem 1rem;
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  background-color: transparent;
`;

const NavA = styled.a`
  @media (min-width: 992px) : {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
  display: block;
  padding: 0.8rem 1rem;
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  background-color: transparent;
`;
