import React from "react";
import BasicTemplate from "../../../templates/BasicTemplate";
import styled from "styled-components";
import VideoCard from "../../../molucules/VideoCard";

const Video: React.FC = (_) => {
  return (
    <BasicTemplate>
      <StyledContainer>
        <VideoCard
          src={"https://cdn.salon.nes-global.com/mov_hts-samp005.mp4"}
          articleId={1}
          title={"video"}
        />
      </StyledContainer>
    </BasicTemplate>
  );
};

export default Video;

const StyledContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
