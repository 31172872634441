import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/utiils/PrivateRoute";
import Login from "./components/pages/Login";
import SignupWithCredit from "./components/pages/Signup";
import MyPage from "./components/pages/MyPage";
import Top from "./components/pages/Top";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import Article from "./components/pages/Article";
import ArticleDetail from "./components/pages/Article/Detail";
import UserStore from "./contexts/UserContext";
import Video from "./components/pages/Article/Video";
import SignupComplete from "./components/pages/Signup/Complete";
import Reservation from "./components/pages/Reservation";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <UserStore>
          <Router>
            <Switch>
              <Route exact component={Top} path="/" />
              <Route component={Login} path="/login" />
              <Route exact component={SignupWithCredit} path="/signup" />
              <Route exact component={SignupComplete} path="/signup/complete" />
              <PrivateRoute exact component={Article} path="/articles" />
              <PrivateRoute
                exact
                component={Reservation}
                path="/reservations"
              />
              <PrivateRoute exact component={Video} path="/videos" />
              <PrivateRoute
                component={ArticleDetail}
                path="/articles/:articleId"
              />
              <PrivateRoute component={MyPage} path="/mypage" />
            </Switch>
          </Router>
        </UserStore>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
