import { createMuiTheme } from "@material-ui/core/styles";

export const BACKGROUND_COLOR = "#FFF";
export const BACKGROUND_LIGHT_BLUE_COLOR = "#F3FBFE";
export const TEXT_COLOR_WHITE = "#FFF";
export const TEXT_COLOR_ERROR = "#F44336";
export const PRIMARY_COLOR = "#1673DF";
export const SECONDARY_COLOR = "#FFF";
export const FOOTER_COLOR = "#1D1D35";
export const TRANSPARENT = "transparent";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "Arial",
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      "Meiryo",
      "sans-serif",
    ].join(","),
  },
});
