import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AccountTemplate from "../../templates/AccountTemplate";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../atoms/PrimaryButton";
import styled from "styled-components";
import {
  GetPlanDetailResponse,
  SignupRequest,
  SignupResponse,
} from "../../../types/models";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import StripeInput from "../../utiils/StripeInput";
import { useGetApi } from "../../../hooks/useGetApi";
import useQuery from "../../../hooks/useQuery";
import { postAsync } from "../../../modules/apiClient";
import { setToken } from "../../../modules/auth";
import ErrorMessage from "../../atoms/ErrorMessage";
import Pricing from "../../molucules/Pricing";
import { Box } from "@material-ui/core";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY!);

const SignupWithCreditCard: React.FC = (_) => {
  const { register, handleSubmit, errors } = useForm<SignupRequest>();
  const [error, setError] = useState("");
  const query = useQuery();
  const history = useHistory();
  const elements = useElements();
  const { data, loading, statusCode } = useGetApi<GetPlanDetailResponse>(
    `/plans/${query.planId}`
  );
  const stripe = useStripe();

  if (loading) {
    return <></>;
  }

  if (statusCode === 404) {
    return (
      <AccountTemplate title="会員登録">
        対象のプランが見つかりませんでした
      </AccountTemplate>
    );
  }

  const needCredit = 0 < (data?.price ?? 0);
  const onSubmit = handleSubmit(async ({ name, email, password }) => {
    const signUpRequest: SignupRequest = {
      name,
      email,
      password,
      planId: query.planId as string,
    };
    if (needCredit) {
      if (!stripe || !elements) {
        return;
      }
      const cardNumber = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(cardNumber!, {});
      if (result.error) {
      } else {
        signUpRequest.source = result.token?.id;
      }
    }
    try {
      const { status, data } = await postAsync<SignupResponse>(
        "/register",
        signUpRequest
      );
      if (status === 200) {
        setToken(data.token);
        history.push("/signup/complete");
      }
    } catch (e) {
      const { data } = e.response;
      setError(data.error.description);
    }
  });
  return (
    <AccountTemplate title="会員登録">
      <Pricing
        title={data?.name!}
        description={data?.description!}
        planId={data?.planId!}
        subheader={data?.featuredMessage!}
        price={data?.price!}
        buttonText={""}
        buttonVariant={"outlined"}
        interval={data?.interval!}
        needButton={false}
      />
      <Box paddingBottom={"10px"} />
      <form onSubmit={onSubmit}>
        <InputText
          fullWidth
          variant="outlined"
          name="name"
          error={Boolean(errors.name)}
          inputRef={register({
            required: {
              value: true,
              message: "名前を入力してください",
            },
          })}
          helperText={errors?.name?.message}
          label="名前"
        />
        <InputText
          variant="outlined"
          fullWidth
          name="email"
          error={Boolean(errors.email)}
          inputRef={register({
            required: {
              value: true,
              message: "メールアドレスを入力してください",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "メールアドレスの形式が違います",
            },
          })}
          helperText={errors?.email?.message}
          label="メールアドレス"
        />
        <InputText
          fullWidth
          variant="outlined"
          label="パスワード"
          error={Boolean(errors.password)}
          inputRef={register({
            required: {
              value: true,
              message: "パスワードを入力してください",
            },
            minLength: {
              value: 8,
              message: "8文字以上設定してください"
            },
            maxLength: {
              value: 32,
              message: "32文字以下に設定してください"
            }
          })}
          helperText={errors?.password?.message}
          type="password"
          name="password"
        />
        {needCredit && (
          <>
            <InputText
              variant="outlined"
              label="カードナンバー"
              name="ccnumber"
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                },
              }}
              InputLabelProps={{ shrink: true }}
            />
            <InputText
              variant="outlined"
              label="有効期限"
              name="cardex"
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                },
              }}
              InputLabelProps={{ shrink: true }}
            />
            <InputText
              variant="outlined"
              label="CVC"
              name="cvc"
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                },
              }}
              InputLabelProps={{ shrink: true }}
            />
          </>
        )}
        <ErrorMessage>{error}</ErrorMessage>
        <ButtonContainer>
          <PrimaryButton fullWidth type="submit">
            会員登録
          </PrimaryButton>
        </ButtonContainer>
      </form>
    </AccountTemplate>
  );
};

const Wrapper: React.FC = (_) => {
  return (
    <Elements stripe={stripePromise}>
      <SignupWithCreditCard />
    </Elements>
  );
};
export default Wrapper;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  padding: 10px 0px;
  font-size: 16px;
`;

const InputText = styled(TextField)`
  padding-bottom: 8px;
`;
