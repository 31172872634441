import * as store from "store";
const Key: string = "Auth_Token";
const RedirectURLKey: string = "RedirectURL";

/*
 * JWTトークンをパースします
 */
const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

/*
 * 現在のUNIXタイムスタンプを秒単位で取得します
 */
const getCurrentTimeStamp = (): number => {
  const date = new Date();
  const millTimestamp = date.getTime();
  // 秒単位に変換
  return Math.floor(millTimestamp / 1000);
};

export function getToken(): string | undefined {
  const auth: Auth = store.get(Key);
  if (auth == null) {
    return undefined;
  }

  if (auth.exp < getCurrentTimeStamp()) {
    removeToken();
    return undefined;
  }
  return auth.token;
}

export function isAuthorized(): boolean {
  return getToken() !== undefined;
}

export function setToken(token: string): void {
  const decodedJwt = parseJwt(token);

  const auth: Auth = {
    exp: decodedJwt.exp,
    token: token
  };

  store.set(Key, auth);
}

export function removeToken(): void {
  store.remove(Key);
}

export function setRedirectURL(to: string) {
  store.set(RedirectURLKey, to);
}

export function getRedirectURL(): string {
  return store.get(RedirectURLKey);
}
export function removeRedirectURL(): void {
  store.remove(RedirectURLKey);
}

interface Auth {
  token: string;
  exp: number;
}
