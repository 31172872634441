import React from "react";
import styled from "styled-components";

const DetailHeader: React.FC<DetailHeaderProps> = ({ title, postImg }) => {
  return (
    <DetailHeaderWrapper>
      {postImg !== "" && <Image src={postImg} />}
      <Overview>
        <Header>{title}</Header>
      </Overview>
    </DetailHeaderWrapper>
  );
};

export default DetailHeader;

export interface DetailHeaderProps {
  title: string;
  postImg: string;
}

const Image = styled.img`
  width: 100%;
`;

const Overview = styled.div`
  padding: 32px 16px;
`;

const DetailHeaderWrapper = styled.div``;

const Header = styled.h3`
  margin: 0 0 8px;
`;
