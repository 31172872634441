import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AccessTime } from "@material-ui/icons";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";

const ArticleCard: React.FC<ArticleCardProps> = ({
  title,
  image,
  articleId,
  postedAt,
  googleDriveUrl,
}) => {
  const history = useHistory();
  const clickHandle = () => {
    if (googleDriveUrl !== undefined && googleDriveUrl !== "") {
      window.location.href = googleDriveUrl;
    } else {
      history.push(`/articles/${articleId}`);
    }
  };
  return (
    <PostCardWrapper onClick={clickHandle}>
      <Image src={image} />
      <Overview>
        <Header>{title}</Header>
      </Overview>
      <EntryMeta>
        <li>
          <AccessTime
            color="inherit"
            style={{
              verticalAlign: "text-top",
              fontSize: 16,
            }}
          />
          <span>
            {format(new Date(postedAt), "yyyy年MM月dd日", {
              locale: ja,
            })}
          </span>
        </li>
      </EntryMeta>
    </PostCardWrapper>
  );
};

export default ArticleCard;

export interface ArticleCardProps {
  articleId: number;
  title: string;
  image: string;
  description?: string;
  googleDriveUrl?: string;
  postedAt: Date;
}

const Image = styled.img`
  width: 100%;
  border-radius: 4px;
`;

const Overview = styled.div`
  padding-top: 8px;
`;

const PostCardWrapper = styled.article`
  padding: 8px 16px;
  border: 1px solid #dbdbdb;
`;

const Header = styled.h3`
  margin: 0 0 8px;
`;
const EntryMeta = styled.ul`
  color: #757575;
  list-style: none;
  margin: 0;
  margin-bottom: 15px;
  padding-inline-start: 0px;
`;
