import * as React from "react";
import Header from "../organisms/Header";
import Footer from "../organisms/Footer";
import styled from "styled-components";

/**
 * 基礎的なテンプレート
 */
const BasicTemplate: React.FC<BasicTemplateProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Container>{children}</Container>
      <Footer />
    </>
  );
};
export default BasicTemplate;
export interface BasicTemplateProps {}

const Container = styled.div`
  flex-grow: 1;
  margin-top: 116px;
`;
