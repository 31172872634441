import * as React from "react";
import styled from "styled-components";
import { FOOTER_COLOR, TEXT_COLOR_WHITE } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons";

const fontStyle: React.CSSProperties = {
  fontSize: "2em",
  fontSmooth: "antialiased",
  display: "inline-block",
  fontStyle: "normal",
  fontVariant: "normal",
  textRendering: "auto",
  lineHeight: 1,
  paddingRight: ".5em",
  width: "auto"
};
const Footer = () => {
  return (
    <Root>
      <Container>
        <Raw>
          <Company>
            <p>© 2019 Your Company. All rights reserved.</p>
          </Company>
          <SNS>
            <SNSLink>
              <FontAwesomeIcon icon={faFacebookSquare} style={fontStyle} />
            </SNSLink>
            <SNSLink>
              <FontAwesomeIcon icon={faTwitterSquare} style={fontStyle} />
            </SNSLink>
            <SNSLink>
              <FontAwesomeIcon icon={faInstagram} style={fontStyle} />
            </SNSLink>
          </SNS>
        </Raw>
      </Container>
    </Root>
  );
};

export default Footer;

const Root = styled.footer`
  font-weight: 400;
  background-color: ${FOOTER_COLOR};
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  @media (min-width: 992px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: ${TEXT_COLOR_WHITE};

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    flex-wrap: nowrap;
  }
`;

const Raw = styled.div`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
`;

const Company = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

const SNS = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  text-align: right;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

const SNSLink = styled.a`
  color: ${TEXT_COLOR_WHITE};
`;
